import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Logo from "../../assets/images/shared/logo.svg";

const Loader = ({ isLoading }) => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100vh",
      }}
    >
      <ClipLoader
        color={"#16437B"}
        loading={isLoading}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <img src={Logo} style={{ position: "absolute", height: 50, width: 50 }} />
    </div>
  );
};

export default Loader;
