import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Flex from "@react-css/flex";
import { Row, Col, Nav } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import classes from "./Header.module.scss";
import { Link } from "react-router-dom";
import logo from "../../assets/images/shared/logo.svg";
import menuItemCircle from "../../assets/images/shared/menu-item-circle.svg";
import i18n from "../../i18n";
import { isArabic } from "../../helpers/helpersFunctions";
import { HashLink } from "react-router-hash-link";

const MenuItems = ({ headerData, t }) => {
  const [showBox, setShowBox] = useState(false);
  const [showAboutBox, setShowAboutBox] = useState(false);
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    window.location.replace("about#getToKnowUs");
  };

  /**
   *
   * @param {*} lng
   */
  const changeLanguage = (lng) => {
    window.location.reload();
    i18n.changeLanguage(lng);
    localStorage.setItem("userLanguage", lng);
    document.documentElement.dir = lng === "ar" ? "rtl" : "ltr";
    document.documentElement.lang = lng;
  };

  return (
    <Flex
      className="d-flex w-100"
      height={132}
      style={{ zIndex: "100", background: "white" }}
    >
      <Container fluid>
        <Row className="align-items-center pt-3">
          <Col lg={5} md={5}>
            <ul
              className={
                isArabic ? classes.menuItemsHolderAr : classes.menuItemsHolder
              }
            >
              <li>
                <Link to="/our-programs">
                  {isArabic ? headerData[0]?.value_ar : headerData[0]?.value}
                </Link>
                <img
                  className="mt-1"
                  style={{ margin: "0 auto" }}
                  src={menuItemCircle}
                  alt="menu circle"
                />
              </li>
              <li>
                <Link to="/eligibility">
                  {isArabic ? headerData[1]?.value_ar : headerData[1]?.value}
                </Link>
                <img
                  className="mt-1"
                  style={{ margin: "0 auto" }}
                  src={menuItemCircle}
                  alt="menu circle"
                />
              </li>
              <li>
                <Link to="/our-youth">
                  {isArabic ? headerData[2]?.value_ar : headerData[2]?.value}
                </Link>
                <img
                  className="mt-1"
                  style={{ margin: "0 auto" }}
                  src={menuItemCircle}
                  alt="menu circle"
                />
              </li>

              <div
                className={classes.hoverBoxHolder}
                style={{ position: "relative" }}
                onMouseEnter={() => setShowAboutBox(true)}
              >
                <li>
                  <Link to="#">
                    {isArabic ? headerData[3]?.value_ar : headerData[3]?.value}
                  </Link>
                  <img
                    className="mt-1"
                    style={{ margin: "0 auto" }}
                    src={menuItemCircle}
                    alt="menu circle"
                  />
                </li>
                <Flex
                  column
                  className={
                    isArabic
                      ? classes.menuAboutDropBoxAr
                      : classes.menuAboutDropBox
                  }
                  onMouseLeave={() => setShowAboutBox(false)}
                  style={{
                    display: showAboutBox ? "flex" : "none",
                    left: isArabic && "-60px",
                  }}
                >
                  <div className={classes.boxFirstLink}>
                    <HashLink smooth to="/about#overview">
                      {isArabic
                        ? headerData[9]?.value_ar
                        : headerData[9]?.value}
                    </HashLink>
                  </div>
                  <div className={classes.boxSecondLink}>
                    <HashLink smooth to="/about#getToKnowUs">
                      {isArabic
                        ? headerData[10]?.value_ar
                        : headerData[10]?.value}
                    </HashLink>
                  </div>
                  <div
                    className={classes.boxSecondLink}
                    style={{ marginTop: "5px" }}
                  >
                    <HashLink smooth to="/about#governance">
                      {isArabic
                        ? headerData[11]?.value_ar
                        : headerData[11]?.value}
                    </HashLink>
                  </div>
                  <div
                    className={classes.boxSecondLink}
                    style={{ marginTop: "5px" }}
                  >
                    <HashLink smooth to="/about#stratigicPartners">
                      {isArabic
                        ? headerData[12]?.value_ar
                        : headerData[12]?.value}
                    </HashLink>
                  </div>
                </Flex>
              </div>
              <li>
                <Link
                  style={{ fontWeight: "bolder" }}
                  to={window.location.pathname}
                  onClick={() => {
                    i18n.resolvedLanguage === "ar"
                      ? changeLanguage("en")
                      : changeLanguage("ar");
                  }}
                >
                  {i18n.resolvedLanguage === "ar" ? "English" : "العربية"}
                </Link>
                <img
                  className="mt-1"
                  style={{ margin: "0 auto" }}
                  src={menuItemCircle}
                  alt="menu circle"
                />
              </li>
            </ul>
          </Col>
          <Col
            lg={1}
            md={1}
            className="d-flex align-items-center pl-0 pr-0 pb-4"
            style={{
              padding: 0,
              justifyContent: isArabic ? "center" : "flex-end",
            }}
          >
            <Navbar.Brand style={{ margin: 0, height: "100px" }}>
              <Link to="/">
                <img src={logo} alt="AlAman" />
              </Link>
            </Navbar.Brand>
          </Col>
          <Col lg={6} md={6}>
            <ul
              className={
                isArabic ? classes.menuItemsHolderAr : classes.menuItemsHolder
              }
            >
              <li>
                <Link to="/contact">
                  {isArabic ? headerData[4]?.value_ar : headerData[4]?.value}
                </Link>
                <img
                  className="mt-1"
                  style={{ margin: "0 auto" }}
                  src={menuItemCircle}
                  alt="menu circle"
                />
              </li>
              <li>
                <Link to="/our-impact">
                  {isArabic ? headerData[5]?.value_ar : headerData[5]?.value}
                </Link>
                <img
                  className="mt-1"
                  style={{ margin: "0 auto" }}
                  src={menuItemCircle}
                  alt="menu circle"
                />
              </li>
              <div
                className={classes.hoverBoxHolder}
                style={{ position: "relative" }}
                onMouseEnter={() => setShowBox(true)}
              >
                <li>
                  <Link to="#">
                    {isArabic ? headerData[6]?.value_ar : headerData[6]?.value}
                  </Link>
                  <img
                    className="mt-1"
                    style={{ margin: "0 auto" }}
                    src={menuItemCircle}
                    alt="menu circle"
                  />
                </li>
                <Flex
                  column
                  className={
                    isArabic ? classes.menuDropBoxAr : classes.menuDropBox
                  }
                  onMouseLeave={() => setShowBox(false)}
                  style={{
                    display: showBox ? "flex" : "none",
                    left: isArabic && "-60px",
                  }}
                >
                  <div className={classes.boxFirstLink}>
                    <Link to="/volunteer">
                      {isArabic
                        ? headerData[7]?.value_ar
                        : headerData[7]?.value}
                    </Link>
                  </div>
                  <div className={classes.boxSecondLink}>
                    <Link to="/employ-an-orphan">
                      {isArabic
                        ? headerData[8]?.value_ar
                        : headerData[8]?.value}
                    </Link>
                  </div>
                  <div
                    style={{ marginTop: "10px" }}
                    className={classes.boxSecondLink}
                  >
                    <Link to="/work-with-us">
                      {isArabic
                        ? headerData[13]?.value_ar
                        : headerData[13]?.value}
                    </Link>
                  </div>
                </Flex>
              </div>
              <li>
                <a href="https://congratulatingalhussein.alamanfund.jo/">
                  {isArabic ? 'نبارك للحسين' : 'Congratulating Al Hussein'}
                </a>
                <img
                  className="mt-1"
                  style={{ margin: "0 auto" }}
                  src={menuItemCircle}
                  alt="menu circle"
                />
              </li>
              {/* <li className={classes.donateBtn}> */}
              <Nav.Link href="/donate" className={classes.donateBtn}>
                {t("Donate")}
              </Nav.Link>
              {/* </li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </Flex>
  );
};

export default withTranslation()(MenuItems);
